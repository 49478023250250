<template>
  <fieldset>
    <legend class="sr-only">Styles</legend>
    <ul class="pl-0 list-none">
      <li v-for="(value, key) in styles" :key="key" class="mb-1">
        <div class="rtb-flex items-center">
          <code class="mr-2">{{ key }}</code>
          <color-picker :value="value" @input="onInput(key, $event)" />
        </div>
      </li>
    </ul>
  </fieldset>
</template>

<script>
import Vue from "vue"
import { debounce } from "lodash"

import ColorPicker from "./ColorPicker.vue"

export default Vue.extend({
  name: "StylesEditor",
  components: {
    ColorPicker
  },
  props: {
    styles: {
      /** @type {import('vue').PropType<import('../types').Styles}> */
      type: Object,
      required: true
    }
  },
  created() {
    this.onInput = debounce(this.onInput, 400)
  },
  methods: {
    /**
     * @param {string} key
     * @param {import('./types').ColorPickerInputEventPayload} value
     */
    onInput(key, value) {
      /** @type {import('./types').StylesEditorInputEventPayload} */
      const payload = { styles: { ...this.styles, [key]: value } }
      this.$emit("input", payload)
    }
  }
})
</script>

<template>
  <v-menu v-model="menu" bottom :close-on-content-click="false">
    <template #activator="{ on }">
      <div
        role="button"
        :style="activatorStyle"
        class="w-6 h-6 rounded-full"
        v-on="on"
      ></div>
    </template>
    <v-card>
      <color-picker v-model="model" />
    </v-card>
  </v-menu>
</template>

<script>
import Vue from "vue"
import { Chrome } from "vue-color"

export default Vue.extend({
  name: "ColorPicker",
  components: {
    "color-picker": Chrome
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    model: {
      /** @returns {string} */
      get() {
        return this.value
      },
      /** @param {{ hex: string }} value */
      set({ hex }) {
        /** @type {import('./types').ColorPickerInputEventPayload} */
        const payload = hex
        this.$emit("input", payload)
      }
    },
    /** @returns {Record<string, string>} */
    activatorStyle() {
      return { backgroundColor: this.value }
    }
  }
})
</script>

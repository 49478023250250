var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fieldset", [
    _c("legend", { staticClass: "sr-only" }, [_vm._v("Styles")]),
    _c(
      "ul",
      { staticClass: "pl-0 list-none" },
      _vm._l(_vm.styles, function(value, key) {
        return _c("li", { key: key, staticClass: "mb-1" }, [
          _c(
            "div",
            { staticClass: "rtb-flex items-center" },
            [
              _c("code", { staticClass: "mr-2" }, [_vm._v(_vm._s(key))]),
              _c("color-picker", {
                attrs: { value: value },
                on: {
                  input: function($event) {
                    return _vm.onInput(key, $event)
                  }
                }
              })
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }